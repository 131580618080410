import React from 'react';

const Footer = () => {
  return (
    <footer className="flex items-center justify-center mx-12 w-auto h-12 bg-gradient-to-r from-white to-white via-[#1FBCFF] rounded-md text-white shadow-md p-4 mt-4">
      <span className="text-xs md:text-sm font-medium">
        © Copyright 2024, All Rights Reserved
      </span>
    </footer>
  );
};

export default Footer;
