import React, { useState } from "react";
import tonverse_logo from "../assets/tonverse-logo-gif-500.webp";
import { Link } from "react-scroll";

function Header() {
  // State to manage mobile menu visibility
  const [isNavOpen, setIsNavOpen] = useState(false);

  return (
    <header className="relative flex flex-col bg-white bg-opacity-5 backdrop-filter backdrop-blur-md backdrop-brightness-60 text-white p-1 md:px-10 px-4 justify-between items-center border-b-2 border-gray-400 border-opacity-20 w-full h-auto z-20">
      <div className="flex justify-between items-center w-full">
        {/* Logo */}
        <div className="flex items-center">
          <img
            src={tonverse_logo}
            className="h-16 w-auto"
            alt="Tonverse Logo"
          />

          <div classname="flex items-center font-big-shoulders font-bold text-2xl">
            <div className="font-big-shoulders font-bold text-2xl">TON</div>
            <div className="font-big-shoulders font-bold text-2xl -mt-2">
              VERSE
            </div>
          </div>
        </div>

        {/* Orta Menü Öğeleri - Desktop */}
        <nav className="hidden md:flex space-x-8 text-sm">
          <Link
            to="whatis"
            smooth={true}
            duration={500}
            className="hover:text-gray-300 cursor-pointer"
          >
            What Is TonVerse?
          </Link>
          <Link
            to="ourmission"
            smooth={true}
            duration={500}
            className="hover:text-gray-300 cursor-pointer"
          >
            Our Mission
          </Link>
          <Link
            to="achievements"
            smooth={true}
            duration={500}
            className="hover:text-gray-300 cursor-pointer"
          >
            Achievements
          </Link>
        </nav>

        <div className="flex items-center relative">
          {/* Sağ Taraf - Dil Seçici */}
          <div
            className="flex items-center space-x-3 px-3 py-1 border border-gray-500 rounded-full text-white cursor-pointer"
            onClick={() => window.open("https://t.me/tonverse_me", "_blank")}
          >
            <span className="text-xs font-medium">JOIN CHAT</span>
          </div>

          {/* Hamburger Menu Button - Mobile */}
          <button
            className="block md:hidden ml-4 p-2 text-white focus:outline-none"
            onClick={() => setIsNavOpen(!isNavOpen)}
          >
            <svg
              className="w-6 h-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      {isNavOpen && (
        <nav className="flex flex-col items-start pl-6 space-y-2 mt-4 md:hidden w-full ">
          <Link
            to="whatis"
            smooth={true}
            duration={500}
            className="hover:text-gray-300 cursor-pointer"
          >
            What Is TonVerse?
          </Link>
          <Link
            to="ourmission"
            smooth={true}
            duration={500}
            className="hover:text-gray-300 cursor-pointer"
          >
            Our Mission
          </Link>
          <Link
            to="achievements"
            smooth={true}
            duration={500}
            className="hover:text-gray-300 cursor-pointer"
          >
            Achievements
          </Link>
        </nav>
      )}
    </header>
  );
}

export default Header;
