import React from 'react';
import './App.css';
import Header from './components/Header';
import OurMission from './components/OurMission';
import WhatIs from './components/WhatIs';
import Featured from './components/Featured';
import GameChanger from './components/GameChanger';
import Mission from './components/Mission';
import Stone from './components/Stone';
import StartNow from './components/StartNow';
import Footer from './components/Footer';

import sparks from './assets/fire-sparks.webp';
import bgLine from './assets/bg-lines.svg';
import ScrollProgressBar from './components/ScrollProgressBar';
import VideoPage from './components/Videopage';

function App() {
  return (

    <div className="relative z-0 bg-tonverse-black text-white min-h-screen max-w-full font-raleway space-y-20 -mt-20">

      <ScrollProgressBar />

      {/* Gradyan Arkaplan */}
      <div className="absolute hidden md:block w-96 h-96 rounded-full bg-[#1FBCFF] -top-36 -left-36 opacity-30 blur-3xl -z-10"></div>
      <div className="absolute hidden md:block w-60 h-60 rounded-full bg-[#1FBCFF] top-[600px] -right-32 opacity-30 blur-3xl -z-10"></div>
      <img className="absolute w-full h-auto bottom-0 mix-blend-lighten  " src={sparks}></img>
      <div className='absolute hidden md:block w-full h-auto bottom-0'>
        <div className='flex flex-row items-center justify-center'>
          {Array.from({ length: 4 }, (_, index) => (
            <img key={index} src={bgLine} alt="bg-line" className="w-1/4 h-auto -z-10" />
          ))}
        </div>
      </div>


      <Header />
      <OurMission />
      <WhatIs />
      <VideoPage />
      <GameChanger />
      <Mission />
      <Stone />
      <StartNow />
      <Footer />
      
      {/*}

      <nav className="flex justify-between items-center p-6">
        <div className="flex items-center space-x-4">
          <img src="logo-url.png" alt="Tonverse Logo" className="h-8" /> 
          <div className="space-x-6">
            <a href="#overview" className="hover:text-gray-400">Overview</a>
            <a href="#media" className="hover:text-gray-400">Media</a>
            <a href="#news" className="hover:text-gray-400">News</a>
          </div>
        </div>
        <div className="space-x-4">
          <span className="hover:text-gray-400">EN</span>
        </div>
      </nav>


      <div className="text-center p-6">
        <h2 className="text-lg uppercase text-gray-400 mb-2">Our mission is to onboard</h2>
        <h1 className="text-7xl font-bold mb-4 font-big-shoulders">THE NEXT 100M</h1>
        <h2 className="text-lg uppercase text-gray-400">Users on Ton</h2>
      </div>

      
      <div className="flex justify-around items-center mt-8">
        <div className="text-center">
          <h3 className="text-5xl font-big-shoulders font-bold">100K</h3>
          <p className="text-lg text-gray-400">Wallet Connects</p>
        </div>
        <div className="text-center">
          <h3 className="text-5xl font-big-shoulders font-bold">100K</h3>
          <p className="text-lg text-gray-400">Monthly Users</p>
        </div>
      </div>


      <div className="flex justify-center mt-12 relative">
        <img src={crystalImage} alt="Crystal" className="relative z-10 h-80" />
      </div>

      
      <div className="mt-12 text-center px-6">
        <p className="text-lg text-gray-400">
          We specialize in creating cutting-edge visual content that captivates audiences and brings ideas to life.
          Whether you need stunning graphics.
        </p>
        <div className="mt-4 space-x-4">
          <a href="#" className="hover:text-gray-400">Twitter</a> /
          <a href="#" className="hover:text-gray-400"> Linkedin</a> /
          <a href="#" className="hover:text-gray-400"> Instagram</a>
        </div>
      </div> */}
    </div>
  );
}

export default App;
