import React from "react";
import { Element } from "react-scroll";
const WhatIs = () => {
  return (
    <Element name="whatis">
    <div className="relative text-white font-raleway lg:top-24 px-16 max-w-screen-2xl mx-auto">
      <div className="flex justify-center flex-col md:flex-row items-center md:space-x-10">
        {/* Sol Blok: Metin ve Buton */}
        <div className="flex flex-col flex-grow space-y-5 text-white text-center md:text-left max-w-lg md:max-w-none md:w-full">
          <h2 className="text-6xl md:text-6xl font-black font-big-shoulders">
            What is TONVERSE?
          </h2>
          <p className="text-xl md:text-2xl leading-relaxed font-thin">
          Step into the future of mobile gaming with <p className="inline font-bold">TONVERSE</p>, the groundbreaking platform built on the powerful TON blockchain. <p className="inline font-bold">TONVERSE</p> is set to transform the gaming landscape by delivering immersive experiences that are not only entertaining but also rewarding. With the decentralized strength of TON, transparency, security, and fair gameplay are guaranteed for everyone. 
          Join us in revolutionizing mobile gaming and unlocking limitless possibilities for players and developers alike.
          </p>
          <div className="flex justify-center md:justify-start">

          <button className="relative text-white items-center font-bold  w-1/4 md:w-2/12 h-auto py-2 px-4 rounded-lg transition duration-300 ease-in-out border border-white bg-gradient-to-r from-[rgba(255,255,255,0.5)] to-[rgba(255,255,255,0.5)] bg-[#1FBCFF] hover:bg-opacity-80 shadow-lg"
          onClick = {() => window.open("https://t.me/tonverse_news", "_blank")}
          >
           Check Updates
          </button>
          </div>
        </div>

    
      </div>
    </div>
    </Element>
  );
};

export default WhatIs;
