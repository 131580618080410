import React from "react";
import sword from "../assets/sword.webp";

const GameChanger = () => {
  return (
    <>
      {/* Big Screen */}

      <div class="relative  hidden md:flex  text-white px-12 max-w-screen-2xl mx-auto pt-40 justify-center">
        <div class="relative flex-shrink-0 w-full xl:max-w-2xl  lg:max-w-lg md:max-w-sm  ">
          <img
            src={sword}
            alt="Papo"
            class="w-full h-auto object-contain mx-auto "
          />

          <div class="absolute inset-0 flex items-center justify-center mix-blend-lighten">
            <div class="w-96 h-96 bg-[#1FBCFF] rounded-full opacity-15 blur-3xl"></div>
          </div>
        </div>

        <div class="flex-grow ml-8 space-y-10  my-auto">
          <h2 class="text-4xl md:text-5xl font-black font-big-shoulders tracking-wider ">
            PAPO: A GAME CHANGER
          </h2>
          <p class="text-sm md:text-md leading-relaxed font-thin w-3/4">
            Embark on an unforgettable adventure with{" "}
            <p className="inline font-bold">PAPO</p>, our flagship mobile gaming
            project powered by the revolutionary TON blockchain. Dive into a
            world of strategic gameplay where every decision profoundly impacts
            your journey. Engage in thrilling battles, master intricate resource
            management, and experience the rich, immersive gameplay that will
            keep you coming back for more.
            <p className="mt-4">
              As you conquer challenges and progress through the game,{" "}
              <p className="inline font-bold">YOU'LL EARN</p> valuable
              blockchain tokens. Use these tokens to enhance your gameplay,
              unlock exclusive content, or even trade them for{" "}
              <p className="inline font-bold">REAL-WORLD VALUE.</p>
            </p>
            <p className="mt-4">
              <p className="inline font-bold">JOIN PAPO</p> today and be part of
              a groundbreaking gaming revolution!
            </p>
          </p>
        </div>
      </div>

      {/* Mobile */}

      <div class="relative flex-row md:hidden mt-10 text-white px-12 items-center justify-center mx-auto">
        <div class="relative flex-row w-full ">
          <img
            src={sword}
            alt="Papo"
            class="w-full h-auto object-contain mx-auto "
          />

          <div class="absolute inset-0 flex items-center justify-center mix-blend-lighten">
            <div class="w-96 h-96 bg-[#1FBCFF] rounded-full opacity-15 blur-3xl"></div>
          </div>
        </div>

        <div class="space-y-5 text-center ">
        <h2 class="text-4xl md:text-5xl font-black font-big-shoulders tracking-wider ">
            PAPO: A GAME CHANGER
          </h2>
          <p class="text-sm md:text-md leading-relaxed font-thin  ">
            Embark on an unforgettable adventure with{" "}
            <p className="inline font-bold">PAPO</p>, our flagship mobile gaming
            project powered by the revolutionary TON blockchain. Dive into a
            world of strategic gameplay where every decision profoundly impacts
            your journey. Engage in thrilling battles, master intricate resource
            management, and experience the rich, immersive gameplay that will
            keep you coming back for more.
            <p className="mt-4">
              As you conquer challenges and progress through the game,{" "}
              <p className="inline font-bold">YOU'LL EARN</p> valuable
              blockchain tokens. Use these tokens to enhance your gameplay,
              unlock exclusive content, or even trade them for{" "}
              <p className="inline font-bold">REAL-WORLD VALUE.</p>
            </p>
            <p className="mt-4">
              <p className="inline font-bold">JOIN PAPO</p> today and be part of
              a groundbreaking gaming revolution!
            </p>
          </p>
        </div>
      </div>
    </>
  );
};

export default GameChanger;
