import React, { useState, useEffect } from 'react';
const ScrollProgressBar = () => {
  const [scroll, setScroll] = useState(0);

  const onScroll = () => {
    const totalScroll = document.documentElement.scrollTop;
    const windowHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    const scrollProgress = (totalScroll / windowHeight) * 100;
    setScroll(scrollProgress);
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <div className="hidden md:flex fixed left-3 top-1/2 transform -translate-y-1/2 flex-col items-center">
      <svg width="15" height="298" viewBox="0 0 21 298" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="6.39844" y="4.05664" width="5.73721" height="5.73721" transform="rotate(-45 6.39844 4.05664)" fill="white"/>
<rect x="6.39844" y="284.941" width="5.73721" height="5.73721" transform="rotate(-45 6.39844 284.941)" fill="white"/>
<rect x="6.39844" y="12.1294" width="5.73721" height="5.73721" transform="rotate(-45 6.39844 12.1294)" fill="white"/>
<rect x="6.39844" y="293.014" width="5.73721" height="5.73721" transform="rotate(-45 6.39844 293.014)" fill="white"/>
 <path d="M10.7996 35.2021H1.26562V252.649L10.7996 262.183H19.9668V44.3694L10.7996 35.2021Z" stroke="white" stroke-width="1.46676"/>
        <path
          d="M10.7996 35.2021H1.26562V252.649L10.7996 262.183H19.9668V44.3694L10.7996 35.2021"
          fill="url(#paint0_linear_13_43)"
          style={{ fillOpacity: 1,  height: `0%`,}}
          clipPath='url(#clipPath)'
        />
        <defs>

        <clipPath id="clipPath">
            {/* Scroll durumuna göre `y` ve `height` dinamik olarak ayarlanır */}
            <rect x="0" y="35" width="21" height={(scroll / 100) * 227} />
          </clipPath>


          <linearGradient id="paint0_linear_13_43" x1="10.6162" y1="35.2021" x2="10.6162" y2="262.183" gradientUnits="userSpaceOnUse">
            <stop offset="0%" stop-color="white" stop-opacity="0"/>
            <stop offset="100%" stop-color="white" stop-opacity="1"/>
          </linearGradient>
        </defs> 
      </svg>
    </div>
  );
};

export default ScrollProgressBar;
