import React from "react";
import videoThumbnail from "../assets/video-thumbnail.webp"; // Video önizleme görselini import edin
import { Element } from "react-scroll";

const VideoPage = () => {
  return (

    <Element name='ourmission' >
    <div className="relative text-white font-raleway lg:top-32 px-12 max-w-screen-2xl mx-auto">
      <div className="flex justify-center flex-col items-center text-center ">
        {/* Sağ Blok: Görsel ve Oynatma Simgesi */}
        <div className=" flex justify-center items-center ">
          <div>
            <div className="absolute hidden md:block w-80 h-80 rounded-full bg-[#1FBCFF] opacity-30 blur-3xl transform -translate-x-full -translate-y-full right-10 -z-0"></div>
          </div>
          <img
            src={videoThumbnail}
            alt="TONVERSE"
            className="rounded-lg shadow-lg w-full h-auto z-0 mix-blend-screen"
          />
        </div>

        {/* Sol Blok: Metin ve Buton */}
        <div className="flex flex-col  text-white max-w-lg md:max-w-none md:w-1/2 mt-10">
          <h2 className="text-3xl md:text-5xl font-black font-big-shoulders  tracking-wide ">
            OUR MISSION IS TO ONBOARD THE NEXT 100 MILLION USERS ON TON 
          </h2>
        </div>
      </div>
    </div>
    </Element>
  );
};

export default VideoPage;
