import React from "react";

import backgroundImage from "../assets/sword-bg.webp"; // Arka plan resmini import edin

const Mission = () => {
  return (
    <div
      className="flex items-center justify-center mx-auto max-w-screen-2xl mt-20 md:px-12"
      
    >
      <div 
      className="text-white font-black font-big-shoulders text-4xl leading-relaxed text-center bg-opacity-70 bg-black p-8 rounded-lg"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.80), rgba(0, 0, 0, 0.80)), url(${backgroundImage}`, // Arka plan resmini ekleyin
        backgroundSize: "cover", // Resmi tam genişlikte kırpar
        backgroundPosition: "center", // Ortadan hizalar
        width: "100%", // Genişlik tam sayfa
        overflow: "hidden", // Taşan kısımları gizler
      }}
      >
        We specialize in creating cutting-edge visual content that captivates
        audiences and brings ideas to life. Whether it's through stunning
        graphics, engaging videos, or immersive animations, our expertise
        ensures your message not only reaches but resonates with your audience.
      </div>
    </div>
  );
};

export default Mission;
