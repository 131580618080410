import React from "react";

import stoneImage from "../assets/stone.webp";
import line from "../assets/line.svg";
import flatLine from "../assets/flat-line.svg";
import blueCircle from "../assets/blue-circle.svg";
import { Element } from "react-scroll";

const texts = [
  "Successfully raised over $1 MILLION in funding.",
  "Acquired a robust user base of more than 400,000 individuals.",
  "Launched multiple successful cryptocurrency projects.",
  "Drove over $100 MILLION in trading volume.",
  "Secured partnerships with top market makers and leading exchanges in the blockchain industry.",
  "Cultivated strong relationships with major Key Opinion Leaders (KOLs) in the Web3 market.",
];

const MobileLine = ({ text }) => {
  return (
    <div className="flex flex-row justify-start items-center space-x-6">
      <img src={blueCircle} alt="" className="transform w-8 " />
      <div className="font-raleway text-lg font-light leading-relaxed ">
        {text}
      </div>
    </div>
  );
};


const Container = () => {
  return (
    <div className="flex justify-center items-center ">
      <div className="grid grid-cols-3 text-white">
        {/* Left side */}

        <div className="col-span-1 flex flex-col font-bold justify-between my-5">

          <div className="flex flex-row justify-end items-center space-x-8 h-1/4">
            <div className="font-raleway  ">Successfully raised over $1 MILLION in funding. </div>
            <img src={blueCircle} alt="" className=" h-1/3" />
            <img src={line} alt="" className="h-1/3 lg:-mb-5 md:-mb-3" />
          </div>

          <div className="flex flex-row justify-end items-center space-x-8 h-1/4">
            <div className="font-raleway ">Acquired a robust user base of more than 400,000 individuals. </div>
            <img src={blueCircle} alt=""  className="transform h-1/3 " />
            <img src={flatLine} alt="" className="transform " />
          </div>


          <div className="flex flex-row justify-end items-center space-x-8 h-1/4">
            <div className="font-raleway ">Launched multiple successful cryptocurrency projects. </div>
            <img src={blueCircle} alt="" className="h-1/3" />
            <img src={line} alt="" className="transform  scale-y-[-1] lg:mb-5 md:mb-3 h-1/3" />
          </div>
        </div>

        {/* Center Image */}
        <div className="col-span-1 flex justify-center items-center ">
          <img src={stoneImage} alt="stone"  className="w-full mix-blend-screen" />
          <div class="absolute transform flex items-center justify-center mix-blend-lighten">
            <div class="w-96 h-96 bg-[#1FBCFF] rounded-full opacity-15 blur-2xl"></div>
          </div>
        </div>

        {/* Right Side */}
        <div className="col-span-1 flex flex-col justify-between my-5">
          <div className="flex flex-row items-center space-x-8 h-1/4">
            <img src={line} alt="" className="transform scale-x-[-1] lg:-mb-5 md:-mb-3 h-1/3" />
            <img src={blueCircle} alt="" className="h-1/3 " />
            <div className="font-raleway ">Drove over $100 MILLION in trading volume. </div>
          </div>

          <div className="flex flex-row items-center space-x-8 h-1/4">
            <img src={flatLine} alt="" className=" " />
            <img src={blueCircle} alt="" className="h-1/3" />
            <div className="font-raleway ">Secured partnerships with top market makers and leading exchanges in the blockchain industry. </div>
          </div>

          <div className="flex flex-row items-center space-x-8 h-1/4">
            <img src={line} alt="" className="transform scale-x-[-1] scale-y-[-1] lg:mb-5 md:mb-3 h-1/3" />
            <img src={blueCircle} alt="" className="h-1/3" />
            <div className="font-raleway ">Cultivated strong relationships with major Key Opinion Leaders (KOLs) in the Web3 market. </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Stone = () => {
  return (
    <Element name="achievements">
    {/* WEB */}
      <div className="hidden md:flex flex-col text-white  font-raleway mx-auto px-12  space-y-12 pt-12 max-w-screen-2xl">
        <div className="text-white font-black font-big-shoulders text-7xl  leading-relaxed text-center w-full">
        TONVERSE ACHIEVEMENTS
        </div>
        <Container />
      </div>

      {/* Mobile */}
      <div className="flex md:hidden flex-col text-white  font-raleway mx-auto px-12  space-y-4 pt-12 max-w-screen-2xl ">
        <div className="text-white font-black font-big-shoulders text-6xl  leading-relaxed text-center w-full">
        TONVERSE ACHIEVEMENTS
        </div>
        <img src={stoneImage} alt="stone" className="w-full mix-blend-screen" />

        <div className="flex flex-col space-y-5">

          {texts.map((text, index) => (
            <MobileLine key={index} text={text} />
          ))}
        </div>
      </div>
    </Element>
  );
};

export default Stone;
