import React from "react";
import characterImage from "../assets/character.webp"; // Yüklediğiniz görselin yolu

const StartNow = () => {
  return (
    <>
      {/* Big Screen */}
      <div className="relative hidden md:flex text-white pt-16 pb-16 px-12 max-w-screen-2xl mx-auto items-center justify-center">
        {/* Görsel Kısmı */}
        <div className="relative flex-shrink-0 w-full xl:max-w-lg lg:max-w-md md:max-w-sm ">
          <img
            src={characterImage}
            alt="Character"
            className="w-full h-auto object-contain mx-auto"
          />
        </div>

        {/* İçerik Kısmı */}
        <div className="flex-grow ml-8 space-y-6 my-auto max-w-lg ">
          <h2 className="text-5xl font-black font-big-shoulders">START NOW</h2>
          <p className="text-sm leading-relaxed font-thin">
            Stay up-to-date with the latest TONVERSE news, updates, and
            exclusive offers. Subscribe to our newsletter today!
          </p>

          {/* Giriş Formu */}
          <div className="space-y-6">
            {/* <input
              type="email"
              placeholder="Email"
              className="w-full p-3 rounded-md opacity-40 bg-white bg-opacity-10 text-white placeholder-gray-400 focus:opacity-60 focus:outline-none focus:ring-2 focus:ring-blue-500 border-2"
            /> */}
            <button className="w-1/3 h-auto py-3 rounded-md text-white font-medium  hover:opacity-80 bg-gradient-to-r from-white to-white via-[#1FBCFF]"
            onClick={() => window.open("https://t.me/tonverse_me", "_blank")}
            >
              Join Community
            </button>
          </div>
        </div>
      </div>

      {/* Mobile */}
      <div className="relative flex flex-col md:hidden text-white px-12 mx-auto  mb-20 items-center mt-10">
        {/* Mobil Görsel Kısmı */}
        <div className="relative w-full mb-6">
          <img
            src={characterImage}
            alt="Character"
            className="w-full h-auto object-contain mx-auto"
          />
        </div>

        {/* Mobil İçerik Kısmı */}
        <div className="w-full space-y-4 text-left">
          <h2 className="text-4xl font-black font-big-shoulders">Start Now</h2>
          <p className="text-md leading-relaxed font-thin">
            Stay up-to-date with the latest TONVERSE news, updates, and
            exclusive offers. Subscribe to our newsletter today!
          </p>

          {/* Giriş Formu */}
          <div className="space-y-4">
            {/* <input
              type="email"
              placeholder="Email"
              className="w-full p-3 rounded-md bg-white bg-opacity-10 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
            /> */}
            <button
              className="w-1/3 h-auto py-3 rounded-md text-white text-xs md:text-sm  font-medium  hover:opacity-80 bg-gradient-to-r from-white to-white via-[#1FBCFF]"
              onClick={() => window.open("https://t.me/tonverse_me", "_blank")}
            >
              Join Community
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default StartNow;
